export class DownloadUrlCreator {
  static create(blob: Blob, name: string, type?: 'PDF' | 'ZIP'): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${name}.${type ? type.toLowerCase() : ''}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
